import React, { useEffect, useState, useCallback } from "react";
import "./EditUser.css";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
//components
import Form from "../../components/Form";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Header from "../../components/Header";

function EditUser() {
  const history = useNavigate();

  const [users, setUsers] = useState([]);

  const { id } = useParams();

  const url = process.env.REACT_APP_URL;

  const getUsers = useCallback(async () => {
    const response = await axios.get(`${url}/${id}`);
    setUsers(response.data);
  }, [id, url]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);


const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    await axios.put(`${url}/${id}/edit`, users);
    history("/");
  } catch (err) {
    console.error(err);
  }
};
  return (
    <div className="edituser">
      <Header title="Edit User" />
      <main>
        <Form onSubmit={handleSubmit}>
          <Input
            setUsers={setUsers}
            name="name"
            type="text"
            value={users.name || users}
          />
          <Input
            setUsers={setUsers}
            name="email"
            type="email"
            value={users.email || users}
          />
          <Input
            setUsers={setUsers}
            name="mobile"
            type="number"
            value={users.mobile || users}
          />
          <Button msg="Edit User" />
        </Form>
      </main>
    </div>
  );
}

export default EditUser;
