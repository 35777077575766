import React, { useCallback, useEffect, useState } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import axios from "axios";
//components
import Header from "../../components/Header";
function Home() {
  const [users, setUsers] = useState([]);
  const url = process.env.REACT_APP_URL;

  const getUsers = useCallback(async () => {
    const response = await axios.get(url);
    setUsers(response.data);
  }, [url]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const deleteUser = async (id) => {
    try {
      await axios.delete(`${url}/${id}/delete`);
      getUsers();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="home">
      <Header title="Lista Użytkowników">
        <div className="home__LinkHeader">
          <Link to="/new-user">New User</Link>
        </div>
      </Header>
      <main>
        <section>
          <ul>
            {users.map((item) => (
              <li key={item.id}>
                <span>{item.name}</span>
                <span>{item.email}</span>
                <span>{item.mobile}</span>
                <button type="button" onClick={() => deleteUser(item.id)}>
                  Delete
                </button>
                <Link to={`/edit-user/${item.id}`}>Edit</Link>
              </li>
            ))}
          </ul>
        </section>
      </main>
    </div>
  );
}

export default Home;
