import React from "react";
import "./Header.css";
function Header({ title, children }) {
  return (
    <header className="header">
      <h2>{title}</h2>
      {children}
    </header>
  );
}

export default Header;
