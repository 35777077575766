import React, { useState } from "react";
import "./NewUser.css";

import axios from "axios";
import { useNavigate } from "react-router-dom";
//components
import Form from "../../components/Form";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Header from "../../components/Header";
export default function NewUser() {
  const [users, setUsers] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const history = useNavigate();
  const url = process.env.REACT_APP_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios.post(`${url}/save`, users);
    history("/");
  };

  return (
    <div className="newuser">
      <Header title="Add New User" />
      <main>
        <Form onSubmit={handleSubmit}>
          <Input
            setUsers={setUsers}
            name="name"
            label="Name"
            type="text"
            value={users.name}
          />
          <Input
            setUsers={setUsers}
            name="email"
            label="E-mail"
            type="email"
            value={users.email}
          />
          <Input
            setUsers={setUsers}
            name="mobile"
            label="Mobile"
            type="number"
            value={users.mobile}
          />
          <Button msg="Add User" />
        </Form>
      </main>
    </div>
  );
}
