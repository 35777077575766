import React from "react";
import "./Input.css";
import TextField from "@mui/material/TextField";

function Input(props) {
  const { value, setUsers, name, label, type } = props;
  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setUsers((values) => ({ ...values, [name]: value }));
  };
  return (
    <TextField
      id="outlined-basic"
      variant="outlined"
      label={label}
      name={name}
      type={type}
      fullWidth
      onChange={handleChange}
      value={value}
    />
  );
}

export default Input;
