import React from "react";
import "./Form.css";
function Form({ children, onSubmit }) {
  return (
    <form onSubmit={onSubmit} className="form">
      <div className="form__container">{children}</div>
    </form>
  );
}

export default Form;
